import React from "react"
import { SiWebpack } from "react-icons/si"
import { BsFiletypeJpg } from "react-icons/bs"

function ToolInfo(props) {
  return (
    <div>
      <div className="page-steps container">
        <h1 className="page-text">
          How to use a WebP to JPG converter
        </h1>
        <span className="page-text01">

Elevate your image accessibility with our WebP to JPG converter, seamlessly transforming WebP files into universally compatible JPG format for seamless cross-device usability.
          </span>
        <div className="page-container01">
          <div className="page-step">
            <div className="page-container02">
              <div className="page-line"/>
              <div className="page-container03">
                <SiWebpack size={30} style={{ color: "#00000080" }} />
              </div>
              <div className="page-line1"/>
            </div>
            <div className="page-container04">
              <h1 className="page-text02"> Click on "Upload WebP image file" and select
                the WebP file from your computer that you want to convert.</h1>
            </div>
          </div>

          <div className="page-step3">
            <div className="page-container11">
              <div className="page-line6"/>
              <div className="page-container12">

                <BsFiletypeJpg size={26} style={{color: "#00000080"}}/>
              </div>
              <div className="page-line7"/>
            </div>
            <div className="page-container13">
              <h3 className="page-text10">Once the conversion is complete, JPG file will automatically downloaded in your device.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolInfo