import React, { useEffect, useState } from "react"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import { convertImageFile } from "../../helpers/ConvertFile"

function FileConverter({acceptFile,format}) {
  const [imageType, setImageType] = useState(null);
  const [isInputEmpty, setIsInputEmpty] = useState(true);

  useEffect(() => {
    setIsInputEmpty(imageType === null);
  }, [imageType])

  return (
    <div className="row align-items-center">
      <div className="row">
        <div className="col-lg-6">
          <div className="file_input_container">
            <label htmlFor="Img_file" className={convertStyled.input_file_upload}> <p> Upload {acceptFile.slice(6).toUpperCase()} image File</p></label>
            <input
              type="file"
              className={convertStyled.file_input}
              onChange={(e) => setImageType(e.target.files[0])}
              accept={acceptFile}
              id="Img_file"
            />
          </div>
          <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`} onClick={() => convertImageFile(imageType,format)} disabled={isInputEmpty}> Download </button>
        </div>
      </div>
    </div>
  )
}

export default FileConverter